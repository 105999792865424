import { Config } from "@/config"
import { ApiResponseLogo, ResponseGetLogo } from "@/interfaces/navbar";
import axios from "axios"

 export const GetLogo = async (token:string)=>{
    try{
        const data = await axios({
            method:"get",
            maxBodyLength:Infinity,
            url:Config.apiEndpoint + `setting/get-logo`,
            headers: {
                "accept-language": "th",
                "x-access-token": token,
                Authorization: "Basic " + Config.encoded,
                "Content-Type": "application/json",
              },
        });
        if (!data || !data.data) {
            console.warn("GetLogo: API response is missing data");
            return undefined;
        }
        const response = data.data as ApiResponseLogo |undefined;
        return response;
   
    
    }catch (error){

    }
 };