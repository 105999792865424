import { IProductItem } from "@/interfaces/product/product";
import { Box, CardContent, Grid, Typography } from "@mui/material"
import Image from "next/image";
import Link from "next/link";

interface Props {
  productAds: IProductItem[]
  adsTitle: string;
}

const ProductAds: React.FC<Props> = ({ productAds, adsTitle }: Props) => {

  const imgDefault = "/assets/images/logo/logomaboom.png";

  return (
    <Box className="section-search-ads">
      <div className="product-ads">
        <Grid container sx={{ padding: "8px" }}>
          <Grid item xs={12} className="title-ads">
            <Typography 
              component="h1"
              style={{ marginBottom: 5 }}
            >{adsTitle}</Typography>
          </Grid>
          {productAds.map((item: IProductItem, index: number) => (
            <Grid item xs={6} sm={6} md={6} lg={3} className="product-thumb" key={index}>
              <Link href={{
                  pathname: `/product/${item.product_slug}`,
                  query: { store: item.store_id, id: item.product_id }
                }}
                className="border-link"
                style={{ pointerEvents: "auto" }}
              >
                <Grid container className="border-link-container">
                  {/* Image Section */}
                  <Grid item xs={4} sm={4} lg={3} className="image-container">
                    <Image
                      src={item.product_thumbnail_image_url || imgDefault}
                      alt={item.product_title || imgDefault}
                      width={100}
                      height={100}
                      style={{ width: "100%", height: "auto", objectFit: "cover", borderRadius: "8px" }}
                    />
                  </Grid>
                  {/* Content Section */}
                  <Grid item xs={8} sm={8} lg={9} className="content-container">
                    <CardContent sx={{ padding: "8px", display: "flex", flexDirection: "column" }}>
                      {/* Title */}
                      <Typography
                        sx={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          WebkitLineClamp: 2,
                          whiteSpace: "normal",
                          color: "#717171",
                          fontSize: "14px",
                          lineHeight: "20px",
                          mb: .5
                          // mb: 1
                        }}
                      >
                        {item.product_title}
                      </Typography>

                      {/* Price */}
                      <Typography sx={{ fontSize: "12px", lineHeight: 1, display: "inline-block" }} className="txt-bold" color="secondary">
                        {item?.product_discounted_price == null || item.product_discounted_price === 0
                          ? new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.product_normal_price)
                          : new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.product_discounted_price)}
                      </Typography>

                      {/* Discounted Price */}
                      {item.product_discounted_price > 0 && (
                        <Box sx={{ fontSize: "10px", textDecoration: "line-through", fontWeight: "bold", color: "#AEAEAE", display: "inline-block" }}>
                          ฿{item.product_normal_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </Box>
                      )}
                    </CardContent>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    </Box>
  )
}

export default ProductAds